import React, { useCallback, useState } from "react";
import { Input, Label, Row, Col, Button } from "reactstrap";
import NavigationFooter from "../NavigationFooter";
import UploadImage from "../UploadImage";
import Required from "../Required";
import { toast } from "react-toastify";
import { Icon } from "../../../components/Component";
import ReactSelect from "../../../components/ReactSelect/ReactSelect";
import { useParams } from "react-router";
import UpdatePassword from "./Edit/UpdatePassword";
import { useQuery } from "react-query";
import { getCities, getCountries, getStates } from "../../../http/get/getApi";
import { transformData } from "../../../utils/Utils";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { checkEmailAvailability } from "../../../http/post/postApi";
import debounce from "lodash.debounce";

const General = ({
  toggle,
  currentState,
  formik,
  isEditOnly,
  clientsListIsLoading,
  isClientAdding,
  isClientEditing,
  isViewOnly,
  dataError,
}) => {
  const { id } = useParams();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [emailError, setEmailError] = useState(true);
  const [emailMessage, setEmailMessage] = useState("");
  const [usernameMessage, setUserNameMessage] = useState("");
  const [emailAvialable, setEmailAvialable] = useState(true);
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [eventClient, setEventClient]=useState(localStorage.getItem("clientroute"))

  function togglePasswordVisibility() {
    setIsPasswordVisible(!isPasswordVisible);
  }

  // onChangeFunctions
  const handelCountryDropdown = (e) => {
    formik.setFieldValue("country", e.value);
  };
  const handelStateDropdown = (e) => {
    formik.setFieldValue("state", e.value);
  };

  const handelCityDropdown = (e) => {
    formik.setFieldValue("city", e.value);
  };

  const [isOpen, setIsOpen] = useState(false);

  const AddEmployeeModal = () => {
    setIsOpen(!isOpen);
  };

  //fetching countries data
  const {
    data: country,
    isLoading: countryLoading,
    isError: countryError,
  } = useQuery({
    queryKey: ["get-country"],
    queryFn: () => getCountries(),
    staleTime: Infinity,
    // staleTime: 60000,
    // cacheTime: 300000,
    retry: 1,
  });

  //optiopns for countries dropdown

  const countriesOptions = transformData({
    dataArray: country?.data?.countries || [],
    label: "name",
    value: "id",
    isLoading: countryLoading,
  });

  let countryId = formik.values.country;

  const {
    data: states,
    isLoading: statesLoading,
    isError: statesError,
  } = useQuery({
    queryKey: ["get-states-by-country", countryId],
    queryFn: () => getStates({ id: countryId }),
    staleTime: Infinity,
    // staleTime: 60000,
    // cacheTime: 300000,
    // retry: 1,
  });

  //optiopns for states dropdown
  const statesOptions = transformData({
    dataArray: states?.data?.states || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });

  let stateId = formik.values.state;

  const {
    data: cities,
    isLoading: citiesLoading,
    isError: citiesError,
  } = useQuery({
    queryKey: ["get-states-by-country", stateId],
    queryFn: () => getCities({ id: stateId }),
    staleTime: Infinity,
    // staleTime: 60000,
    // cacheTime: 300000,
    retry: 1,
  });

  //optiopns for cities dropdown
  const citiesOptions = transformData({
    dataArray: cities?.data?.cities || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });

  const handelValidation = (callbackFun) => {
    // callbackFun(true);

    // if (formik.values.first_name === "") {
    //   formik.setFieldTouched("first_name", true);
    // }

    // if (formik.values.last_name === "") {
    //   formik.setFieldTouched("last_name", true);
    // }

    // if (formik.values.work_phone_number === "") {
    //   formik.setFieldTouched("work_phone_number", true);
    // }

    if (formik.values.email === "") {
      formik.setFieldTouched("email", true);
    }

    if (formik.values.username === "") {
      formik.setFieldTouched("username", true);
    }

    // if (formik.values.country === "") {
    //   formik.setFieldTouched("country", true);
    // }

    // if (formik.values.state === "") {
    //   formik.setFieldTouched("state", true);
    // }

    // if (formik.values.city === "") {
    //   formik.setFieldTouched("city", true);
    // }

    if (formik.values.password === "" && !isEditOnly) {
      formik.setFieldTouched("password", true);
    }


    if (formik.errors.email && formik.values.email !== "") {
      toast.warning("Invalid Email.");
      return;
    }

    if (formik.errors.work_phone_number) {
      toast.warning("Invalid Work Mobile Number.");
      return;
    }
    if (formik.errors.phone_number) {
      toast.warning("Invalid Personal Mobile Number.");
      return;
    }
    if (formik.errors.zip_code) {
      toast.warning("Invalid Zip Code.");
      return;
    }

    if (formik.errors.password && formik.values.password !== "" && !isEditOnly) {
      toast.warning("Invalid password.");
      return;
    }


    if (
      // formik.values.first_name !== "" &&
      // formik.values.last_name !== "" &&
      // formik.values.work_phone_number !== "" &&
      formik.values.email !== "" &&
      formik.values.username !== "" &&
      !formik.errors.zip_code && 
      !formik.errors.phone_number &&
      !formik.errors.work_phone_number &&
      // formik.values.country !== "" &&
      // formik.values.state !== "" &&
      // formik.values.city !== "" &&
      (isEditOnly || formik.values.password !== "")
    ) {
      callbackFun(true);
    }
  };

  const clientTypeOptions = [
    { value: "1", label: "Individual" },
    { value: "2", label: "Company" },
    { value: "3", label: "Organization" },
    { value: "4", label: "School" },
  ];

  const handleClientTypeChange = (e) => {
    formik.setFieldValue("client_type", e.target.value);
    // console.log("Client type:", formik.values.client_type);
  };

  const handleInputChange = (field, e) => {
    const { value } = e.target;

    // Calculate word count for the specific field
    let wordCount = 0;
    if (value.trim() !== "") {
      wordCount = value.trim().split(/\s+/).length;
    }

    // Update the field's value and its word count in Formik
    formik.setFieldValue(field, value);
    formik.setFieldValue(`${field}Count`, wordCount);
  };

  // const handleEmailChange = (e) => {
  //   formik.handleChange(e);
  //   const email = e.target.value;
  //   const username = formik.values.username;
  //   checkEmailAvailability({email, username});
  // };
  const debouncedCheckEmailAvailability = useCallback(
    debounce((email) => {
      checkEmailAvailability({ data: email, type: "email" })
        .then((response) => {
          console.log("Availability Response:", response.status);
          setEmailError(response.status);
          if(response.data.email == true){
            setEmailMessage("Email is available");
            setEmailAvialable(true)
          }else{
            setEmailMessage("Email is already taken");
            setEmailAvialable(false)
          }
          // setErrorMessage(response.message);
        })
        .catch((error) => {
          console.error("Error checking email availability:", error);
        });
    }, 3000), // 5000ms (5s) debounce delay
    []
  );

  const debouncedCheckEmailAvailability1 = useCallback(
    debounce((username) => {
      checkEmailAvailability({ data: username, type: "username" })
        .then((response) => {
          console.log("Availability Response:", response.status);
          setEmailError(response.status);
          if(response.data.username == true){
            setUserNameMessage("Username is available");
            setUsernameAvailable(true)
          }else{
            setUserNameMessage("Username is already taken");
            setUsernameAvailable(false)
          }
        })
        .catch((error) => {
          console.error("Error checking email availability:", error);
        });
    }, 3000), // 5000ms (5s) debounce delay
    []
  );

  const handleEmailChange = (e) => {
    formik.handleChange(e);
    const email = e.target.value;

    // Use the memoized debounced function
    debouncedCheckEmailAvailability(email);
  };
  const handleUsernameChange = (e) => {
    formik.handleChange(e);
    const username = e.target.value;

    // Use the memoized debounced function
    debouncedCheckEmailAvailability1(username);
  };

  return (
    <>
      {clientsListIsLoading && isEditOnly ? (
        <div>
          <Row>
            <Col md={6} className="border p-3 bg-white">
              <Row>
                <Col md={6} className=" p-3 bg-white">
                  <ShimmerThumbnail height={250} width={300} rounded />
                  <ShimmerThumbnail height={40} width={300} rounded />
                  <ShimmerThumbnail height={40} width={300} rounded />
                </Col>
                <Col md={6} className=" p-3 bg-white">
                  <ShimmerThumbnail height={50} width={300} rounded />
                  <ShimmerThumbnail height={50} width={300} rounded />
                  <ShimmerThumbnail height={50} width={300} rounded />
                  <ShimmerThumbnail height={50} width={300} rounded />
                </Col>
              </Row>
            </Col>
            <Col md={6} className=" border p-3 bg-white">
              <Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <>
          <Row className="p-2 py-0">
            {/* First Section */}
            <Col md={6} className="border p-3 bg-white">
              <section>
                <Row>
                  <Col sm={12} md={7} lg={6}>
                    <UploadImage label="Upload Profile Picture" name="files" formik={formik} isViewOnly={isViewOnly} />
                  </Col>
                  <Col sm={12} md={7} lg={6}>
                    <div>
                      <Label for="first_name">
                        First Name
                        {/* <Required /> */}
                      </Label>
                      <Input
                        readOnly={isViewOnly}
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={formik.values.first_name}
                        placeholder="Enter first name"
                        // onChange={formik.handleChange}
                        onChange={(e) => handleInputChange("first_name", e)}
                        onBlur={formik.handleBlur}
                        // invalid={formik.errors.first_name && formik.touched.first_name}
                      />
                      {/* {formik.errors.first_name && formik.touched.first_name && (
                        <p style={{ color: "red" }}>{formik.errors.first_name}</p>
                      )} */}
                    </div>
                    <div className="mt-4">
                      <Label htmlFor="last_name">
                        Last Name
                        {/* <Required /> */}
                      </Label>
                      <Input
                        readOnly={isViewOnly}
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={formik.values.last_name}
                        placeholder="Enter last name"
                        // onChange={formik.handleChange}
                        onChange={(e) => handleInputChange("last_name", e)}
                        onBlur={formik.handleBlur}
                        // invalid={formik.errors.last_name && formik.touched.last_name}
                      />
                      {/* {formik.errors.last_name && formik.touched.last_name && (
                        <p style={{ color: "red" }}>{formik.errors.last_name}</p>
                      )} */}
                    </div>
                    <div className="mt-4">
                      <Label for="organization_name">Organization Name</Label>
                      <Input
                        readOnly={isViewOnly}
                        type="text"
                        id="organization_name"
                        name="organization_name"
                        value={formik.values.organization_name}
                        placeholder="Enter organization name"
                        // onChange={formik.handleChange}
                        onChange={(e) => handleInputChange("organization_name", e)}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2 g-3">
                  <Col sm={12} md={7} lg={6}>
                    <div>
                      <Label for="work_phone_number">
                        Office Mobile Number
                        {/* <Required /> */}
                      </Label>
                      <Input
                        readOnly={isViewOnly}
                        type="number"
                        id="work_phone_number"
                        name="work_phone_number"
                        value={formik.values.work_phone_number}
                        placeholder="Enter Office mobile number"
                        // onChange={formik.handleChange}
                        onChange={(e) => handleInputChange("work_phone_number", e)}
                        onBlur={formik.handleBlur}
                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        // invalid={formik.errors.work_phone_number && formik.touched.work_phone_number}
                      />
                      {formik.errors.work_phone_number && formik.touched.work_phone_number && (
                        <p style={{ color: "red" }}>{formik.errors.work_phone_number}</p>
                      )}
                    </div>
                  </Col>
                  <Col sm={12} md={7} lg={6}>
                    <div>
                      <Label for="phone_number">Personal Mobile Number</Label>
                      <Input
                        readOnly={isViewOnly}
                        type="number"
                        id="phone_number"
                        name="phone_number"
                        value={formik.values.phone_number}
                        placeholder="Enter Personal Mobile number"
                        // onChange={formik.handleChange}
                        onChange={(e) => handleInputChange("phone_number", e)}
                        onBlur={formik.handleBlur}
                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        invalid={formik.errors.phone_number && formik.touched.phone_number}
                      />
                      {formik.errors.phone_number && formik.touched.phone_number && (
                        <p style={{ color: "red" }}>{formik.errors.phone_number}</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={12} md={7} lg={6}>
                    <div>
                      <Label for="clientType">Select Client Type</Label>
                      <select
                        disabled={isViewOnly}
                        id="clientType"
                        name="client_type"
                        value={formik.values.client_type}
                        onChange={handleClientTypeChange}
                        onBlur={() => formik.setFieldTouched("client_type", true)}
                        className="form-control" // Bootstrap class for styling
                      >
                        <option value="" disabled>
                          Select a type
                        </option>
                        {clientTypeOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      {formik.errors.client_type && formik.touched.client_type && (
                        <p style={{ color: "red" }}>{formik.errors.client_type}</p>
                      )}
                    </div>
                  </Col>
                  <Col sm={12} md={7} lg={6}>
                    {formik.values.client_type === "1" ? null : formik.values.client_type === "2" ? (
                      <div>
                        <Label for="company_detail">Company Details</Label>
                        <Input
                          readOnly={isViewOnly}
                          minLength={3}
                          maxLength={255}
                          id="company_detail"
                          placeholder="Enter company details"
                          type="text"
                          name="company_detail"
                          value={formik.values.company_detail}
                          // onChange={formik.handleChange}
                          onChange={(e) => handleInputChange("company_detail", e)}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    ) : formik.values.client_type === "3" ? (
                      <div>
                        <Label for="organization_detail">Organization Details</Label>
                        <Input
                          readOnly={isViewOnly}
                          minLength={3}
                          maxLength={500}
                          id="organization_detail"
                          placeholder="Enter organization details"
                          type="text"
                          name="organization_detail"
                          value={formik.values.organization_detail}
                          // onChange={formik.handleChange}
                          onChange={(e) => handleInputChange("organization_detail", e)}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    ) : formik.values.client_type === "4" ? (
                      <div>
                        <Label for="school_detail">School Details</Label>
                        <Input
                          readOnly={isViewOnly}
                          minLength={3}
                          maxLength={500}
                          id="school_detail"
                          placeholder="Enter school details"
                          type="text"
                          name="school_detail"
                          value={formik.values.school_detail}
                          // onChange={formik.handleChange}
                          onChange={(e) => handleInputChange("school_detail", e)}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </section>
            </Col>
            {/* Second Section */}
            <Col md={6} className="border p-3 bg-white border-start-0">
              <section>
                {/* First Row */}
                <Row className="g-2">
                  <Col sm={12} md={12} lg={4}>
                    <div>
                      <Label for="email">
                        Email
                        <Required />
                      </Label>
                      <Input
                        readOnly={isViewOnly}
                        disabled={isEditOnly}
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter email"
                        value={formik.values.email}
                        // onChange={formik.handleChange}
                        onChange={(e) => handleEmailChange(e)}
                        onBlur={formik.handleBlur}
                        invalid={formik.errors.email && formik.touched.email}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <p style={{ color: "red" }}>{formik.errors.email}</p>
                      )}
                      {emailError && emailMessage == "Email is already taken" ? (
                        <p style={{ color: emailMessage == "Email is already taken" ? "red" : "green" }}>{emailMessage}</p>
                      ) : (
                        <p style={{ color: emailMessage == "Email is available" ? "green" : "red" }}>{emailMessage}</p>
                      )}
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={4}>
                    <div>
                      <Label for="username">
                        Username
                        <Required />
                      </Label>
                      <Input
                        readOnly={isViewOnly}
                        disabled={isEditOnly}
                        type="text"
                        id="username"
                        name="username"
                        placeholder="Enter username"
                        value={formik.values.username}
                        // onChange={formik.handleChange}
                        onChange={(e) => handleUsernameChange(e)}
                        onBlur={formik.handleBlur}
                        invalid={formik.errors.username && formik.touched.username}
                      />
                      {formik.errors.username && formik.touched.username && (
                        <p style={{ color: "red" }}>{formik.errors.username}</p>
                      )}
                      {emailError && usernameMessage == "Username is already taken" ? (
                        <p style={{ color: usernameMessage == "Username is already taken" ? "red" : "green" }}>{usernameMessage}</p>
                      ) : (
                        <p style={{ color: usernameMessage == "Username is available" ? "green" : "red" }}>{usernameMessage}</p>
                      )}
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={4}>
                    <div className="form-control-wrap">
                      <Label for="password" className="d-flex justify-content-between align-items-center">
                        <div>
                          Password
                          {!isEditOnly && <Required />}
                        </div>
                      </Label>
                      <div className="form-icon form-icon-right mt-4">
                        <Icon
                          name={isPasswordVisible ? "ni ni-eye" : "ni ni-eye-off"}
                          className="icon mt-2"
                          onClick={togglePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <Input
                        readOnly={isViewOnly}
                        type={isPasswordVisible ? "text" : "password"}
                        id="password"
                        name="password"
                        className="form-control"
                        value={formik.values.password}
                        disabled={!!id}
                        placeholder={isPasswordVisible ? "Enter password" : "● ● ● ● ● ● ●"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik.errors.password && formik.touched.password}
                      />
                      {formik.errors.password && !isEditOnly && formik.touched.password && (
                        <p style={{ color: "red" }}>{formik.errors.password}</p>
                      )}
                    </div>
                  </Col>
                </Row>
                {/* Second Row */}
                <Row className="mt-2 g-2">
                  <Col sm={12} md={12} lg={4}>
                    <div>
                      <Label for="address_1">Address Line 1</Label>
                      <Input
                        readOnly={isViewOnly}
                        id="address_1"
                        placeholder="Enter Address Line 1"
                        name="address_1"
                        value={formik.values.address_1}
                        // onChange={formik.handleChange}
                        onChange={(e) => handleInputChange("address_1", e)}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={4}>
                    <div>
                      <Label for="address_2">Address Line 2</Label>
                      <Input
                        readOnly={isViewOnly}
                        id="address_2"
                        placeholder="Enter Address Line 2"
                        name="address_2"
                        value={formik.values.address_2}
                        // onChange={formik.handleChange}
                        onChange={(e) => handleInputChange("address_2", e)}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={4}>
                    <div>
                      <Label for="zip_code">Zip-code</Label>
                      <Input
                        readOnly={isViewOnly}
                        id="zip_code"
                        type="number"
                        placeholder="Enter zip code"
                        name="zip_code"
                        value={formik.values.zip_code}
                        // onChange={formik.handleChange}
                        onChange={(e) => handleInputChange("zip_code", e)}
                        onBlur={formik.handleBlur}
                        invalid={formik.errors.zip_code && formik.touched.zip_code}
                      />
                      {formik.errors.zip_code && formik.touched.zip_code && (
                        <p style={{ color: "red" }}>{formik.errors.zip_code}</p>
                      )}
                    </div>
                  </Col>
                </Row>
                {/* Third Row */}
                <Row className="mt-2 g-2">
                  <Col sm={12} md={12} lg={4}>
                    <div>
                      <Label for="country">
                        Select Country
                        {/* <Required /> */}
                      </Label>
                      <ReactSelect
                        disabled={isViewOnly}
                        options={countriesOptions}
                        id="country"
                        name="country"
                        value={formik.values.country}
                        onChange={(e) => handelCountryDropdown(e)}
                        onBlur={() => formik.setFieldTouched("country", true)}
                        // invalid={formik.errors.country && formik.touched.country}
                      />
                      {/* {formik.errors.country && !isEditOnly && formik.touched.country && (
                        <p style={{ color: "red" }}>{formik.errors.country}</p>
                      )} */}
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={4}>
                    <div>
                      <Label for="state">
                        Select State
                        {/* <Required /> */}
                      </Label>
                      <ReactSelect
                        disabled={isViewOnly}
                        options={statesOptions}
                        id="state"
                        name="state"
                        value={formik.values.state}
                        onChange={(e) => handelStateDropdown(e)}
                        onBlur={() => formik.setFieldTouched("state", true)}
                        // invalid={formik.errors.state && formik.touched.state}
                      />
                      {/* {formik.errors.state && !isEditOnly && formik.touched.state && (
                        <p style={{ color: "red" }}>{formik.errors.state}</p>
                      )} */}
                    </div>
                  </Col>
                  <Col sm={12} md={12} lg={4}>
                    <div>
                      <Label for="city">
                        Select City
                        {/* <Required />  */}
                      </Label>
                      <ReactSelect
                        disabled={isViewOnly}
                        options={citiesOptions}
                        id="city"
                        name="city"
                        value={formik.values.city}
                        onChange={(e) => handelCityDropdown(e)}
                        onBlur={() => formik.setFieldTouched("city", true)}
                        // invalid={formik.errors.city && formik.touched.city}
                      />
                      {/* {formik.errors.city && !isEditOnly && formik.touched.city && (
                        <p style={{ color: "red" }}>{formik.errors.city}</p>
                      )} */}
                    </div>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col md={12}>
                    <div>
                      <Label for="notes">Notes</Label>
                      <textarea
                        readOnly={isViewOnly}
                        as="textarea"
                        id="notes"
                        maxLength={255}
                        placeholder="Enter notes here"
                        rows={3}
                        className="form-control"
                        name="notes"
                        value={formik.values.notes}
                        // onChange={formik.handleChange}
                        onChange={(e) => handleInputChange("notes", e)}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            </Col>
            {isViewOnly ? (
              ""
            ) : (
              <NavigationFooter
                toggle={toggle}
                currentState={currentState}
                lastPage="1"
                validationFunction={handelValidation}
                formik={formik}
                isClientAdding={isClientAdding}
                isClientEditing={isClientEditing}
                dataError={dataError}
                navigateRoute={eventClient ? eventClient : "/client-list"}
                // emailError={emailError}
                emailAvialable={emailAvialable}
                usernameAvailable={usernameAvailable}
              />
            )}
          </Row>
          <UpdatePassword AddEmployeeModal={AddEmployeeModal} isOpen={isOpen} />
        </>
      )}
    </>
  );
};

export default General;
