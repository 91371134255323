import React, { useContext, useEffect, useState } from "react";
import { Icon, UserAvatar } from "../../components/Component";
import SimpleBar from "simplebar-react";
import { Input, Button } from "reactstrap";
import { ChatItem, ContactItem } from "./ChatPartials";
import { findUpper } from "../../utils/Utils";
import { chatData } from "./ChatData";
import { ChatContext } from "./ChatContext";
import { collection, getDocs, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "../../Firebase Files/firebaseConfig";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import NoData from "../../assets/images/NoData.png";

export const ChatAsideBody = ({
  onInputChange,
  favState,
  favFilter,
  setFavState,
  setSelectedId,
  selectedId,
  favInputSearchChange,
  favFilterText,
  filterTab,
  chatItemClick,
  filteredChatList,
  currentUser,
  selectedUser,
  messages,
  filterText,
  loading,
}) => {
  const [fav, setFav] = useState([
    {
      id: 10,
      name: "Aaron Nunez",
      nickname: "",
      active: true,
      image: "data:image/jpeg;base64,...", // Truncated for brevity
      chatTheme: "purple",
      unread: false,
      archive: false,
      date: "Now",
      fav: false,
      convo: [],
    },
    {
      id: 2,
      name: "Abu Bin Ishtiak",
      nickname: "",
      theme: "blue",
      chatTheme: "purple",
      date: "4.39 Am",
      active: "60m",
      unread: true,
      archive: false,
      fav: true,
      delivered: true,
      convo: [
        {
          id: "chat_1",
          chat: ["Hi I am Ishtiak, can you help me with something?"],
          date: "4:49 AM",
        },
        {
          id: "chat_2",
          me: true,
          chat: ["Thanks for inform. We just solved the issues. Please check now."],
          date: "4:12 PM",
        },
        {
          id: "chat_3",
          chat: ["This is really cool.", "It’s perfect. Thanks for letting me know."],
          date: "4:28 PM",
        },
      ],
    },
  ]);
  const [favData] = fav;


  // const [users, setUsers] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [newArr, setNewArr] = useState([]);

  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     setLoading(true);
  //     try {
  //       // Step 1: Fetch the current user's list of chatting IDs
  //       const usersRef = collection(db, "Users");
  //       const usersQuery = query(usersRef, where("id", "==", currentUser.id));
  //       const userSnapshot = await getDocs(usersQuery);

  //       if (userSnapshot.empty) {
  //         console.error("No user found for the current user ID");
  //         setLoading(false);
  //         return;
  //       }

  //       const currentUserData = userSnapshot.docs[0].data();
  //       const chattingIds = currentUserData?.chattingIds || [];

  //       // console.log("chattingIds: ",chattingIds);

  //       if (chattingIds.length === 0) {
  //         setLoading(false);
  //         return;
  //       }

  //       // Step 2: Fetch chat data for each chatting ID
  //       const chatsRef = collection(db, "Chats");
  //       const chatQueries = chattingIds.map((chatId) =>
  //         query(chatsRef, where("participants", "array-contains", currentUser.id))
  //       );
  //       // Fetch chat details concurrently using Promise.all
  //       const chatSnapshots = await Promise.all(chatQueries.map((chatQuery) => getDocs(chatQuery)));

  //       // Step 3: Process chat data and extract latest messages
  //       const userChatData = await Promise.all(
  //         chatSnapshots.map(async (chatSnapshot, idx) => {
  //           if (chatSnapshot.empty) return null;

  //           const chatDoc = chatSnapshot.docs[idx];
  //           const chatData = chatDoc.data();
  //           const messagesRef = collection(db, "Chats", chatDoc.id, "messages");

  //           const messagesQuery = query(messagesRef, orderBy("timestamp", "desc"));
  //           const messagesSnapshot = await getDocs(messagesQuery);

  //           if (!messagesSnapshot.empty) {
  //             const latestMessage = messagesSnapshot.docs[0].data();
  //             // console.log("latestMessage: ", latestMessage?.senderId);

  //             const recipientId = chatData.participants.find((id) => id !== currentUser?.id);
  //             // console.log("latestMessage Id: ", recipientId);

  //             if (!recipientId) return null;

  //             return {
  //               ...chatData,
  //               latestMessageTimestamp: latestMessage.timestamp,
  //               recipientId,
  //             };
  //           } else {
  //             return null;
  //           }
  //         })
  //       );

  //       // Filter out any null values from the userChatData array
  //       const validChats = userChatData.filter((chat) => chat !== null);

  //       // Step 4: Sort chats by the latest message timestamp in descending order
  //       validChats.sort((a, b) => b.latestMessageTimestamp - a.latestMessageTimestamp);

  //       // Step 5: Fetch user details for recipients
  //       const recipientIds = validChats.map((chat) => chat.recipientId);
  //       setUsers(recipientIds);
  //     } catch (error) {
  //       console.error("Error fetching users or chats:", error);
  //       setUsers([]);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (currentUser?.id) {
  //     fetchUsers();
  //   }
  // }, [filteredChatList]);

  // useEffect(() => {
  //   const matchAndSortArray = async () => {
  //     if (users.length > 0 && filteredChatList) {
  //       // Filter and sort the array
  //       const filteredAndSortedArray = filteredChatList
  //         .filter((chat) => users.includes(chat?.id))
  //         .sort((a, b) => users.indexOf(a.id) - users.indexOf(b.id));

  //       setNewArr(filteredAndSortedArray);
  //     } else {
  //       setNewArr([]); // Clear the array if no matches or empty inputs
  //     }
  //   };

  //   matchAndSortArray();
  // }, [users, filteredChatList]); // Added dependencies

  // console.log("users: ", users);
  // console.log("filteredChatList: ", filteredChatList);
  // console.log("NewArr: ", newArr);

  return (
    <SimpleBar className="nk-chat-aside-body">
      <div className="nk-chat-aside-search">
        <div className="form-group">
          <div className="form-control-wrap">
            <div className="form-icon form-icon-left">
              <Icon name="search"></Icon>
            </div>
            <Input
              type="text"
              className="form-round"
              id="default-03"
              value={filterText}
              placeholder="Search by name"
              onChange={(e) => onInputChange(e)}
            />
          </div>
        </div>
      </div>

      <div className="nk-chat-list">
        <h6 className="title overline-title-alt">{filterTab}</h6>
        <ul className="chat-list">
          {loading ? (
            [1, 2, 3, 4, 5].map((item, idx) => (
              <ShimmerCategoryItem key={idx} hasImage imageType="circular" imageWidth={60} imageHeight={60} text />
            ))
          ) : filteredChatList && filteredChatList.length > 0 ? (
            filteredChatList?.map((item, idx) => {
              if (filterTab === "messages") {
                return (
                  <ChatItem
                    key={idx}
                    item={item}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                    chatItemClick={chatItemClick}
                    currentUser={currentUser}
                    messages={messages}
                  ></ChatItem>
                );
              } else if (filterTab === "archive") {
                return (
                  item.convo.length > 0 &&
                  item.archive &&
                  !item.group && (
                    <ChatItem
                      key={idx}
                      item={item}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      chatItemClick={chatItemClick}
                    ></ChatItem>
                  )
                );
              } else if (filterTab === "unread") {
                return (
                  item.convo.length > 0 &&
                  item.unread &&
                  !item.group && (
                    <ChatItem
                      key={idx}
                      item={item}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      chatItemClick={chatItemClick}
                    ></ChatItem>
                  )
                );
              } else {
                return (
                  item.convo.length > 0 &&
                  !item.channel &&
                  item.group && (
                    <ChatItem
                      key={idx}
                      item={item}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      chatItemClick={chatItemClick}
                    ></ChatItem>
                  )
                );
              }
            })
          ) : (
            <>
              <div className="flex-column justify-center align-center mt-5">
                <div className="h-50 w-50">
                  <div>
                    <img src={NoData} alt="" />
                  </div>
                  <div className="lead-text text-center mt-2">No Chats Yet</div>
                </div>
              </div>
            </>
          )}
        </ul>
      </div>
    </SimpleBar>
  );
};

export const ChannelAsideBody = ({
  filteredChatList,
  onInputChange,
  setSelectedId,
  setMobileView,
  selectedId,
  filterTab,
  chatItemClick,
}) => {
  const defaultChat = filteredChatList.filter((item) => item.group === true);
  return (
    <SimpleBar className="nk-chat-aside-body">
      <div className="nk-chat-aside-search">
        <div className="form-group">
          <div className="form-control-wrap">
            <div className="form-icon form-icon-left">
              <Icon name="search"></Icon>
            </div>
            <Input
              type="text"
              className="form-round"
              id="default-03"
              placeholder="Search by name"
              onChange={(e) => onInputChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="nk-chat-aside-panel nk-chat-channel">
        <h6 className="title overline-title-alt">All Channels</h6>
        <ul className="channel-list">
          {chatData.map((item, idx) => {
            return (
              item.channel && (
                <li
                  key={idx}
                  onClick={() => {
                    setSelectedId(item.id);
                    if (window.innerWidth < 860) setMobileView(true);
                  }}
                >
                  <a
                    href="#name"
                    onClick={(ev) => ev.preventDefault()}
                    className={selectedId === item.id ? "active" : ""}
                  >
                    # {item.name}
                  </a>
                </li>
              )
            );
          })}
        </ul>
      </div>
      <div className="nk-chat-list">
        <h6 className="title overline-title-alt">Teams / Groups</h6>
        <ul className="chat-list">
          {defaultChat.length !== 0 ? (
            filteredChatList.map((item, idx) => {
              if (filterTab === "messages") {
                return (
                  item.convo.length > 0 &&
                  item.group &&
                  !item.archive &&
                  !item.channel && (
                    <ChatItem
                      key={idx}
                      item={item}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      chatItemClick={chatItemClick}
                    ></ChatItem>
                  )
                );
              } else if (filterTab === "archive") {
                return (
                  item.convo.length > 0 &&
                  !item.channel &&
                  item.archive &&
                  item.group && (
                    <ChatItem
                      key={idx}
                      item={item}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      chatItemClick={chatItemClick}
                    ></ChatItem>
                  )
                );
              } else if (filterTab === "unread") {
                return (
                  item.convo.length > 0 &&
                  !item.channel &&
                  item.unread &&
                  item.group && (
                    <ChatItem
                      key={idx}
                      item={item}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      chatItemClick={chatItemClick}
                    ></ChatItem>
                  )
                );
              } else {
                return (
                  item.convo.length > 0 &&
                  !item.channel &&
                  item.group && (
                    <ChatItem
                      key={idx}
                      item={item}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      chatItemClick={chatItemClick}
                    ></ChatItem>
                  )
                );
              }
            })
          ) : (
            <p className="m-3">No group found</p>
          )}
        </ul>
      </div>
    </SimpleBar>
  );
};

export const ContactAsideBody = (onInputChange, filterData, filterText, contactData, setSelectedId) => {
  return (
    <SimpleBar className="nk-chat-aside-body">
      <div className="nk-chat-aside-search">
        <div className="form-group">
          <div className="form-control-wrap">
            <div className="form-icon form-icon-left">
              <Icon name="search"></Icon>
            </div>
            <input
              type="text"
              className="form-round form-control"
              id="default-03"
              placeholder="Search by name"
              onChange={(e) => onInputChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="nk-chat-aside-panel nk-chat-contact">
        {filterData.length === 0 ? (
          filterText ? (
            <div className="ms-5">No user</div>
          ) : (
            contactData.map((item, idx) => {
              return <ContactItem key={idx} item={item}></ContactItem>;
            })
          )
        ) : (
          <ul className="contacts-list">
            {filterData.map((contact, idx) => {
              return (
                <React.Fragment>
                  <li key={idx} setSelectedId={setSelectedId(contact.id)}>
                    <div className="user-card">
                      <a href="#name" onClick={(ev) => ev.preventDefault()}>
                        <UserAvatar
                          text={findUpper(contact.name)}
                          theme={contact.theme}
                          image={contact.image}
                        ></UserAvatar>
                        <div className="user-name">{contact.name}</div>
                      </a>
                      <div className="user-actions">
                        <a href="#start-chat" onClick={(ev) => ev.preventDefault()}>
                          Start Chat
                        </a>
                      </div>
                    </div>
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
        )}
      </div>
    </SimpleBar>
  );
};
